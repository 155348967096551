import { useDispatch, useSelector } from "react-redux";
import { Button, Page, TopNav } from "../../shared/components";
import { RadioButton } from "../../shared/components/RadioButton";

import { LANGUAGES } from "../../constants/languages";
import { setLanguage } from "../../utils/redux/actions/language.action";
import { useNavigate } from "react-router-dom";
import { initialize } from "../../utils/redux/actions/network.actions";

const LanguagesScreen = () => {
  const {
    network,
    language,
  } = useSelector((state) => state);
  const { chosenLanguage } = language;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSelectLanguage = (l) => {
    dispatch(setLanguage(l));
    dispatch(
      initialize({
        msisdn: network.call.headers.msisdn,
        region: network.call.headers.region,
        environment: network.call.headers.environment,
        isoRegion: network.call.headers.country_code_iso3,
        //Ln: l.code

      }),
    );
  };
  return (
    <Page isBottomBar={false}>
      <div style={{ padding: 8, paddingTop: 0 }}>
        <TopNav
          title={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage?.language
              ?.title
          }
          enableSearch={false}
          centerTitle={true}
        />
        {LANGUAGES[network.call?.headers?.region].map((l) => (
          <RadioButton
            isActive={chosenLanguage && chosenLanguage.code == l.code}
            selected={chosenLanguage && chosenLanguage.code == l.code}
            style={{
              backgroundColor: "transparent",
              padding: 16,
              marginBottom: 0,
            }}
            body={l.name}
            clickHandler={() => {
              onSelectLanguage(l);
            }}
            key={l.code}
          />
        ))}
      </div>
      <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 40 }}>
        <Button
          caption={
            language?.chosenPack?.language?.COPY?.SCREENS?.manage?.language
              ?.select_button
          }
          clickHandler={() => navigate(-1)}
        />
      </div>
    </Page>
  );
};

export default LanguagesScreen;
