import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {TopNav, Page} from "../../shared/components";
import {TermsAndConditionsScreen as events} from "../../analytics/events";
import { TermsAndConditionsUrls } from "../../constants/appConstants";
// import { uri } from "../../constants/appConstants";

const TermsAndConditionsScreen = () => {
  const dispatch = useDispatch();

  const { profile, language, network } = useSelector((state) => state);
  const { call } = network;

  const { chosenLanguage } = language;
  const region = call?.headers?.region ?? null;

  const [html, setHTML] = useState({ __html: "" });

  useEffect(async () => {
    async function createMarkup() {
      let response;
      response = await fetch(
        TermsAndConditionsUrls[region][chosenLanguage.code],
        {
          headers: {
            msisdn: `${profile?.data?.msisdn}`.replace("+", ""),
          },
        },
      );

      const backendHtmlString = await response.text();
      return { __html: backendHtmlString };
    }
    return createMarkup().then((result) => setHTML(result));
  }, []);

  useEffect(() => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.terms_and_conditions_screen(
        network?.call?.headers?.isoRegion,
        network?.call?.headers.environment
      ),
    });
  }, []);

  return (
    <Page isBottomBar={false}>
      <TopNav
        title={
          <p style={{ marginLeft: 20 }}>
            {
              language?.chosenPack?.language?.COPY?.SCREENS?.manage
                ?.terms_and_conditions?.title
            }
          </p>
        }
        enableSearch={false}
        showCloseIcon={false}
        centerTitle={true}
      />
      <div style={{ padding: 20 }} dangerouslySetInnerHTML={html} />
    </Page>
  );
};

export default TermsAndConditionsScreen;