import {motion} from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { Border } from "..";
import { StyledFaqs } from "./Faqs.styles";
import { useSelector } from "react-redux";
import { FAQs } from "../../../constants/appConstants";

const Faqs = () => {
  const { network, language } = useSelector((state) => state);
  const { call } = network;

  const [selectedItem, setSelectedItem] = useState(false);
  // const [defaultItemTracked, setDefaultItemTracked] = useState(false);

  const { chosenLanguage } = language;
  const region = call?.headers?.region ?? null;

  const selectedFaqs = useMemo(() => {
    if (chosenLanguage && region) {
      return FAQs[region][chosenLanguage.code];
    } else {
      return null;
    }
  }, [chosenLanguage, region]);

  useEffect(() => {
    console.log("selected", selectedItem);
    // if (defaultItemTracked) {
    //   if ("dispatch" in events) {
    //     if (
    //       "type" in events.ClickFAQ &&
    //       "event" in events.ClickFAQ &&
    //       Object.keys(events.ClickFAQ.event(selectedItem)).length > 0
    //     ) {
    //       let _event = {
    //         event: events.ClickFAQ.event(selectedItem),
    //         type: events.ClickFAQ.type,
    //       };
    //       events.dispatch(_event);
    //     }
    //   }
    // } else {
    //   setDefaultItemTracked(true);
    // }
  }, [selectedItem]);

  return (
    <StyledFaqs>
      {selectedFaqs && (
        <div className="topic" key={selectedFaqs?.topic}>
          <div className="topic-name">{selectedFaqs?.topic}</div>
          <div className="questions">
            {selectedFaqs.questions?.map((q) => (
              <div
                onClick={() => {
                  q.showAnswer = !q.showAnswer;
                  setSelectedItem(q.value);
                  // const newItems = [...items];
                  // setItems(newItems);
                }}
                key={q?.value} //555741621
                className="question">
                <div className="first-row">
                  <div className="value">{q?.value}</div>
                  <motion.div
                    className={`sort-icon-faq icon ${
                      q?.showAnswer || !q?.showAnswer ? "show" : "normal"
                    }`}></motion.div>
                </div>
                {q?.showAnswer ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="answer">
                    {q.answer}
                  </motion.div>
                ) : (
                  <></>
                )}
                <Border />
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedFaqs === null && (
        <p>Sorry we could not fetch FAQ's, please try again later.</p>
      )}
    </StyledFaqs>
  );
};

export default Faqs;
