export const LANGUAGES = {
  Ghana: [
    {
      code: "en",
      name: "english",
    },
  ],
  Zambia: [
    {
      code: "en",
      name: "english",
    },
  ],
  Cameroon: [
    {
      code: "fr",
      name: "french",
    },
    {
      code: "en",
      name: "english",
    },
  ],
  CIV: [
    {
      code: "fr",
      name: "french",
    },
    {
      code: "en",
      name: "english",
    },
  ],
  Congo: [
    {
      code: "fr",
      name: "french",
    },
    {
      code: "en",
      name: "english",
    },
  ],
  Eswatini: [
    {
      code: "en",
      name: "english",
    },
  ],
};
