import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PWA_ROUTES } from "../../constants/appConstants";

import {
  Page,
  TopNav,
  ContentTopNav,
  ItemInfo,
  SubscribeSection,
} from "../../shared/components";
import { ItemSelected } from "../../HOC";
import { ContentInfoScreen as events } from "../../analytics/events";
import { useAuth0 } from "@auth0/auth0-react";

import "react-html5video/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { StyledSubscribeSection } from "../../shared/components/SubscribeSection/SubscribeSection.styles";
import styled from "styled-components";
import ApiCall from "../../utils/network/apiCalls";
import { selectItem } from "../../utils/redux/actions/subscription.action";
import { SELECTED_SOURCE } from "../../appConstants";

const ContentInfoScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();

  const apiCall = new ApiCall();

  // const {
  // 	redirectUrlObj,
  // 	error
  // } = useSelector((state) => state?.items);

  const { profile } = useSelector((state) => state);
  const { subscriptions } = useSelector((state) => state);
  const {network} = useSelector((state) => state);
  const {
		playInstanceItems,
	  } = useSelector((state) => state?.items);
    
  const [redirectUrl, setRedirectUrl] = useState(undefined);

  const [redirectUrlPass, setRedirectUrlPass] = useState(undefined);

  const hasAuthentication = (function checkAuthenticationState() {
    if (isAuthenticated == true) {
      return true;
    }
    if (profile?.data?.msisdn != undefined && profile?.data?.msisdn != null) {
      return true;
    }

    return false;
  })();

  const selectedItem = subscriptions?.selectedItem;
  const { language } = useSelector((state) => state);


  useEffect(() => {
    setRedirectUrl(undefined);

    const url = new URL(window.location.href);
    const s = Array.from(url.searchParams.keys());
    if (s?.includes("region") && s?.includes("id")) {
      apiCall
        .fetchSharedContent(
          url.searchParams.get("id"),
          url.searchParams.get("region"),
          profile?.data?.msisdn ?? "",
        )
        .then((res) => {
          dispatch(selectItem(res.response, SELECTED_SOURCE.CONTENT));
        })
        .catch((err) => {
          console.log(err);
          navigate(PWA_ROUTES.HOME);
        });
    } else {
      if (selectedItem === undefined || selectedItem === null) {
        navigate(PWA_ROUTES.HOME);
        return;
      }

      if (selectedItem?.url?.includes("?")) {
        setRedirectUrlPass(
          selectedItem?.url +
            "&msisdn=" +
            String(profile?.data?.msisdn).replace("+", "").trim(),
        );
      } else {
        try {
          let a = new URL(selectedItem?.url);
          a.searchParams.set(
            "msisdn",
            String(profile?.data?.msisdn).replace("+", "").trim(),
          );
          setRedirectUrlPass(a.toString());
        } catch (e) {
          setRedirectUrlPass("");
        }
      }
    }
  }, []);

  // useEffect(()=>{
  //   if(redirectUrlObj !== null) {
  //      setRedirectUrl(redirectUrlObj.redirect_to)
  //   }

  // },[redirectUrlObj]);

  useEffect(() => {
    if (redirectUrlPass !== undefined) {
      setRedirectUrl(redirectUrlPass);
    }
  }, [redirectUrlPass]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.content_detail_screen(
        network?.call?.headers?.isoRegion,
        network?.call?.headers.environment,
        selectedItem?.itemType,
        selectedItem?.title,
        selectedItem?.subscriptionType,
        selectedItem?.vendorId,
        selectedItem?.vendor?.title,
      ),
    });
  }, []);

  const determineButtonOutcome = (mSelectedItem) => {
    const result = {};
    result.buttonText =
      mSelectedItem?.subscriptionType === "subscription" &&
      (mSelectedItem?.subscribed === "Inactive" ||
        mSelectedItem?.subscribed === "Pending")
        ? `${language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.subscribe_to} ${mSelectedItem?.service?.name}`
        : mSelectedItem?.subscriptionType === "free"
        ? `${language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.subscribe_to}`
        : mSelectedItem?.subscribed === "Active"
        ? `${language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.watch_now}`
        : mSelectedItem?.subscriptionType === "subscription"
        ? `${language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.subscribe_to} ${mSelectedItem?.service?.name}`
        : "Purchase";
    return result;
  };

  const analyticsWatch = () => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.play_content(
        network?.call?.headers?.isoRegion,
        network?.call?.headers.environment,
        selectedItem?.itemType,
        selectedItem?.title,
        selectedItem?.subscriptionType,
        selectedItem?.vendorId,
        selectedItem?.vendor.title,
      ),
    });
  };

  const onSearchQueryChanged = () => {};

  return (
    <ItemSelected probation={true}>
      <Page isBottomBar={false}>
        <TopNav
          title={selectedItem?.title}
          searchInputCallBack={(query) => onSearchQueryChanged(query)}
          enableSearch={false}
          showCloseIcon={true}
        />
        <ContentTopNav />
        {/*
         *TODO: Add loading spinner
         */}
        <ItemInfo />
        {selectedItem?.subscribed !== "Pending" && (
          <div>
            {playInstanceItems?.message === "full" && !hasAuthentication ? (
              <SubscribeSection
                buttonText= {language?.chosenPack?.language?.COPY?.SCREENS?.serviceDetail?.login_to_sub}
                className="bottom"
              />
            ) : (
              playInstanceItems?.message === "full" &&
              selectedItem?.subscribed !== "Active" && (
                <SubscribeSection
                  {...determineButtonOutcome(selectedItem)}
                  className="bottom"
                />
              )
            )}

            {playInstanceItems?.message === "full" &&
              redirectUrl !== undefined &&
              !selectedItem?.internalPlay &&
              redirectUrlPass !== undefined &&
              selectedItem?.subscribed === "Active" && (
                <StyledSubscribeSection>
                  <div
                    style={{
                      textDecoration: "none",
                      color: "#ffff",
                      width: "100%",
                      marginLeft: "24px",
                      marginRight: "24px",
                    }}>
                    <p
                      style={{
                        color: "#ffff",
                        width: "100%",
                        textAlign: "center",
                        marginBottom: "20px",
                      }}>
                        {language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.new_window_open}.
                    </p>
                    <a
                      onClick={() => {
                        analyticsWatch();
                      }}
                      style={{
                        borderRadius: 40,
                        height: 40,
                        backgroundColor: "#FFCB05",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#000",
                        marginBottom: "20px",
                      }}
                      href={redirectUrlPass}
                      target="_blank"
                      rel="noopener noreferrer">
                      {" "}
                      {language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.play_now}
                    </a>
                  </div>
                </StyledSubscribeSection>
              )}

            {playInstanceItems?.message !== "full" && (
              <StyledSubscribeSection>
                <div
                  style={{
                    textDecoration: "none",
                    color: "#ffff",
                    width: "100%",
                    marginLeft: "24px",
                    marginRight: "24px",
                  }}>
                  <p
                    style={{
                      color: "#ffff",
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "20px",
                    }}>
                      {language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.redirect_option}
                  </p>
                  <a
                    onClick={() => {
                      analyticsWatch();
                    }}
                    style={{
                      borderRadius: 40,
                      height: 40,
                      backgroundColor: "#FFCB05",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#000",
                      marginBottom: "20px",
                      textTransform: "uppercase",
                    }}
                    href={redirectUrlPass}
                    target="_blank"
                    rel="noopener noreferrer">
                    {" "}
                    {language?.chosenPack?.language?.COPY?.SCREENS?.contentInfo?.continue_to} {selectedItem?.service?.name}
                  </a>
                </div>
              </StyledSubscribeSection>
            )}

            {playInstanceItems?.message === "full" &&
              redirectUrl === undefined &&
              !selectedItem?.internalPlay &&
              redirectUrlPass !== undefined &&
              selectedItem?.subscribed === "Active" && (
                <StyledSubscribeSection>
                  <div
                    style={{
                      textDecoration: "none",
                      color: "#ffff",
                      width: "100%",
                      marginLeft: "24px",
                      marginRight: "24px",
                    }}>
                    <a
                      style={{
                        borderRadius: 40,
                        height: 40,
                        backgroundColor: "grey",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#000",
                      }}
                      href={redirectUrlPass}
                      target="_blank"
                      rel="noopener noreferrer">
                      {" "}
                      <StyledButtonLoading></StyledButtonLoading>
                    </a>
                  </div>
                </StyledSubscribeSection>
              )}
          </div>
        )}
      </Page>
    </ItemSelected>
  );
};

const StyledButtonLoading = styled.div`
  width: 30px;
  height: 30px;
  border: 6px solid ${({ primary }) => (primary ? "#fff" : "#333")};
  border-bottom-color: transparent;
  border-radius: 45px;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default ContentInfoScreen;
