import { homeBanners } from "../types";

export const setGhanaBanners = () => (dispatch) => {
  dispatch({
    type: homeBanners.SET_GHANA_BANNERS,
  });
};

export const setZambiaBanners = () => (dispatch) => {
  dispatch({
    type: homeBanners.SET_ZAMBIA_BANNERS,
  });
};

export const setPlaceholderBanners = () => (dispatch) => {
  dispatch({
    type: homeBanners.SET_PLACEHOLDER_BANNERS,
  });
};

export const setCameroonBanners = () => (dispatch) => {
  dispatch({
    type: homeBanners.SET_CAMEROON_BANNERS,
  });
};

export const setCongoBanners = () => (dispatch) => {
  dispatch({
    type: homeBanners.SET_CONGO_BANNERS,
  });
};

export const setCivBanners = () => (dispatch) => {
  dispatch({
    type: homeBanners.SET_CIV_BANNERS,
  });
};
