import { motion } from "framer-motion";
import {useState, useRef, useEffect} from "react";
import { StyledTopNav } from "./TopNav.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../../utils/redux/actions/search.actions";
import { subscriptions } from "../../../utils/redux/actions";
import { TopNavLanguageToggle } from "../TopNavLanguageToggle";

const TopNav = ({
  title,
  centerTitle = false,
  searchInputCallBack,
  enableSearch = true,
  showCloseIcon = false,
  onSearchStateChange,
}) => {
  const { search: searchReduxState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchInput = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {language} = useSelector((state) => state);

  useEffect(() => {
    if (pathname === PWA_ROUTES.SEARCH) {
      setIsSearch(true);
      setSearchTerm(searchReduxState.query);
      /*
       * if user clicked search from Combined Screen. Reset the selected item.
       */
      dispatch(subscriptions.selectItem(null));
    }
  }, []);

  useEffect(() => {
    if (isSearch) {
      if (pathname === PWA_ROUTES.SEARCH) {
        searchInput.current.focus();
      } else {
        navigate(PWA_ROUTES.SEARCH);
      }
    }
    if (typeof onSearchStateChange === "function") {
      onSearchStateChange(isSearch);
    }
  }, [isSearch]);

  const closeScreen = () => {
    // dispatch(subscriptions.selectItem(null));
    // navigate(PWA_ROUTES.HOME);
    navigate(-1);
  };

  const onBackButtonClicked = () => {
if (pathname === "/serviceScreen") {
  navigate("/home");
} else if (
  pathname === "/profile" ||
  pathname === "/subscriptions" ||
  pathname === "/terms" ||
  pathname === "/help" ||
  pathname === "/privacyNoticesScreen" ||
  pathname === "/languages"
) {
  navigate("/settings");
} else {
  navigate(-1);
}
  };

  return (
    <StyledTopNav>
      <div className="brand" style={{ flex: 1 }}>
        {pathname === "/home" ||
        pathname === "/myplay" ||
        pathname === "/payments" ||
        pathname === "/settings" ? (
          <div
            style={{ zIndex: 1 }}
            className={"icon brand-icon play-logo"}></div>
        ) : pathname === "/ContentInfo" ? (
          <></>
        ) : pathname === "/search" ? (
          <div style={{ marginLeft: 12 }}></div>
        ) : pathname === "/ServiceDetailsScreen" ? (
          <></>
        ) : pathname === "/TransactionResScreen" ? (
          <></>
        ) : (
          <div
            style={{ zIndex: 1 }}
            onClick={onBackButtonClicked}
            className={"icon back"}></div>
        )}
        {(pathname === "/home" ||
          pathname === "/terms" ||
          pathname === "/myplay" ||
          pathname === "/profile" ||
          pathname === "/payments" ||
          pathname === "/languages" ||
          pathname === "/serviceScreen" ||
          pathname === "/privacyNoticesScreen" ||
          pathname === "/subscriptions" ||
          pathname === "/help" ||
          pathname === "/privacyNotice" ||
          pathname === "/settings") &&
          !isSearch && (
            <span
              style={{
                flex: 1,
                textAlign: centerTitle ? "center" : "left",
                marginLeft: centerTitle ? -64 : 0,
              }}
              id="title">
              {title}
            </span>
          )}

        {/* <div className="icon brand-icon play-logo"></div>
        {!isSearch && <span id="title">{title}</span>} */}
        {isSearch && (
          <motion.input
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="search-input"
            ref={searchInput}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              dispatch(setSearchQuery(e.target.value));
              if (searchInputCallBack) searchInputCallBack(e.target.value);
            }}
            placeholder={
              language?.chosenPack?.language?.COMPONENTS?.home_search?.search
            }
          />
        )}
      </div>
      {
        //Language toggle
        pathname === "/home" && <TopNavLanguageToggle />
      }
      {enableSearch ? (
        <div className="icons">
          {!isSearch && (
            <div
              onClick={() => {
                setIsSearch(!isSearch);
              }}
              className="icon search"></div>
          )}
          {isSearch && (
            <div
              onClick={() => {
                setIsSearch(!isSearch);
                setSearchTerm("");
                dispatch(setSearchQuery(""));
                navigate(PWA_ROUTES.HOME);
                if (searchInputCallBack) searchInputCallBack("");
              }}
              className="icon close"></div>
          )}
        </div>
      ) : showCloseIcon ? (
        <div onClick={closeScreen} className="icon close"></div>
      ) : (
        <></>
      )}
    </StyledTopNav>
  );
};

export default TopNav;
