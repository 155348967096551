import { useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {HomeScreen as events} from "../../../analytics/events";

const BannerCarousel = () => {
  const homeBanners = useSelector((state) => state.homeBanners);
		const language = useSelector((state) => state.language);
    const carouselRef = useRef(null);
		const dispatch = useDispatch();

		const { chosenLanguage } = language;
    const { network } = useSelector((state) => state);

    const items = useMemo(() => {
      if (chosenLanguage.code.length > 0) {
        return homeBanners[chosenLanguage.code];
      }
      return [];
    }, [homeBanners, chosenLanguage]);

  const onClick = (i) => {
    dispatch({
			type: "ANALYTICS/TRACK_EVENT",
      event: events.banner_hit_home_screen(
        network?.call?.headers?.isoRegion,
				network?.call?.headers.environment,
        items[i].url,
      ),
		  });
    window.open(items[i].url);
  };

  if (!homeBanners) {
    return null;
  }

  return (
    <Carousel
      ref={carouselRef}
      swipeable={false}
      showArrows={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      onClickItem={onClick}
      autoPlay
      infiniteLoop
      useKeyboardArrows={false}
      interval={6000}>
      {items.map((item, i) => (
        <div
          key={i}
          style={{
            height: 220,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${item.img})`,
          }}></div>
      ))}
    </Carousel>
  );
};

export default BannerCarousel;
